















import { ApiTokenResponse } from "@/api/security/types/Responses";
import { Identity } from "@/utils/Identity";
import Vue, { PropType } from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import TeacherClient from "@/api/teacher/TeacherClient";
import _ from "lodash";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "TeachersTable",
  components: { DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClassId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  computed: {
    client(): TeacherClient {
      return new TeacherClient();
    },
    clientFunction(): any {
      return TeacherClient.getTeachers;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolClassId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("users.first_name")),
          value: "first_name",
          filter: {
            key: "first_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].first_name"],
          },
        },
        {
          text: _.toString(this.$t("users.last_name")),
          value: "last_name",
          filter: {
            key: "last_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].last_name"],
          },
        },
        {
          text: _.toString(this.$t("users.email")),
          value: "email",
          filter: {
            key: "email",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].email"],
          },
        },
        {
          text: _.toString(this.$t("users.phone")),
          value: "phone",
          filter: {
            key: "phone",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].phone"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "login_as",
          icon: "mdi-login",
          tooltip: this.$t("users.login_as"),
          disabled: (userToLoginAs: UserResponse) => userToLoginAs.isFounder() || userToLoginAs.isAdmin() || this.$store.getters["ApiToken/isImpersonated"],
          onClick: (userToLoginAs: UserResponse): Promise<ApiTokenResponse> => {
            return Identity.onChangeIdentity(userToLoginAs.id)
              .then((response) => {
                // snackbar
                this.$snackbarSuccess(this.$t("layout.identity_changed"));
                // go to user's dashboard
                this.$router.push({ name: "dashboard" });
                return response;
              });
          },
          show: () => this.$isAdmin(),
        },
        {
          name: "remove",
          icon: "mdi-account-cancel",
          tooltip: this.$t("users.remove_teacher_from_class"),
          disabled: () => this.schoolClass === null, // wait for school class object from parent component
          onClick: (userToRemove: UserResponse): Promise<SchoolClassResponse> => {
            if (this.schoolClass === null) throw "School class property should not be null";
            const request: UpdateSchoolClassRequest = {
              name: this.schoolClass.name,
              children: this.schoolClass.children
                .map((child: ChildResponse) => child.id),
              teachers: this.schoolClass.teachers
                .filter((user: UserResponse) => user.id !== userToRemove.id) // do not include removed teacher ID
                .map((user: UserResponse) => user.id),
            };
            return SchoolClassClient.updateSchoolClass(this.schoolId, this.schoolYearId, this.schoolClassId, request)
              .then((response) => {
                this.$emit("onRemoveTeacher");
                return response;
              });
          },
          refetch: true,
          show: () => this.$isAdmin(),
        },
      ];
    },
  },
  methods: {
    refresh(): void {
      (this.$refs["table"] as any).refresh();
    },
  },
});
