











import Vue, { PropType } from "vue";
import TemporaryLocationForm from "@/components/temporary-locations/form/TemporaryLocationForm.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { TemporaryLocationResponse } from "@/api/temporary-locations/types/Responses";
import { CreateTemporaryLocationRequest } from "@/api/temporary-locations/types/Requests";
import TemporaryLocationClient from "@/api/temporary-locations/TemporaryLocationClient";

export default Vue.extend({
  name: "CreateTemporaryLocationForm",
  components: { TemporaryLocationForm },
  props: {
    children: {
      type: Array as PropType<ChildResponse[]>,
      required: true,
    },
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClassId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateTemporaryLocationRequest): void {
      this.isBusySubmit = true;
      TemporaryLocationClient.createTemporaryLocation(request)
        .then((response: TemporaryLocationResponse[]) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
