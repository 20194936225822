import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { UserResponse } from "@/api/user/types/Responses";
import { PaginatedResponse } from "@/api/common/types/Responses";

export default class SchoolUserClient extends AbstractClient {
  /**
   * Perform get users associated with given school request
   * @param schoolId school ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getUsers(schoolId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<UserResponse>> {
    return AbstractClient.get("/api/schools/" + schoolId + "/users", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(UserResponse, response.data));
  }
}
