








































































































































import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { CreateTemporaryLocationRequest, TemporaryLocationRequest } from "@/api/temporary-locations/types/Requests";
import { required } from "vuelidate/lib/validators";
import { ChildResponse } from "@/api/child/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { AllItems } from "@/api/AbstractClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { TranslateResult } from "vue-i18n";
import moment from "moment";

export default Vue.extend({
  name: "TemporaryLocationForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    children: {
      type: Array as PropType<ChildResponse[]>,
      required: true,
    },
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClassId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySchoolClasses: false,
    // form data
    form: {
      date_from: null as string | null,
      date_to: null as string | null,
      children: [],
      target_school_class: null as number | null,
    } as TemporaryLocationRequest,
    // enum
    schoolClasses: [] as SchoolClassResponse[],
    // dialog
    dateFromDialog: false,
    dateToDialog: false,
  }),
  validations: {
    form: {
      date_from: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isSameOrBefore(moment(this.form.date_to))
            : true;
        },
      },
      date_to: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isSameOrBefore(moment(this.form.date_to))
            : true;
        },
      },
      children: { required },
      target_school_class: { required },
    },
  },
  watch: {
    "form.date_from": function(): void {
      if (this.form.date_from && !this.form.date_to) {
        this.form.date_to = this.form.date_from;
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusySchoolClasses;
    },
    dateFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_from?.$dirty) return errors;
      !this.$v.form.date_from.required && errors.push(this.$t("temporary_locations.validation.date_from.required"));
      !this.$v.form.date_from.valid && errors.push(this.$t("temporary_locations.validation.date_from.valid"));
      return errors;
    },
    dateToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_to?.$dirty) return errors;
      !this.$v.form.date_to.required && errors.push(this.$t("temporary_locations.validation.date_to.required"));
      !this.$v.form.date_to.valid && errors.push(this.$t("temporary_locations.validation.date_to.valid"));
      return errors;
    },
    targetSchoolClassErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.target_school_class?.$dirty) return errors;
      !this.$v.form.target_school_class.required && errors.push(this.$t("temporary_locations.validation.target_school_class.required"));
      return errors;
    },
    childrenErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.children?.$dirty) return errors;
      !this.$v.form.children.required && errors.push(this.$t("temporary_locations.validation.children.required"));
      return errors;
    },
    dateFromFormatted(): string {
      return this.form.date_from ? moment(this.form.date_from).format("LL") : "";
    },
    dateToFormatted(): string {
      return this.form.date_to ? moment(this.form.date_to).format("LL") : "";
    },
  },
  methods: {
    fetchSchoolClasses(): void {
      this.isBusySchoolClasses = true;
      SchoolClassClient.getSchoolClasses(this.schoolId, this.schoolYearId, new AllItems())
        .then((response) => {
          // do not offer current class
          this.schoolClasses = response.items.filter((schoolClass) => schoolClass.id !== this.schoolClassId);
        })
        .finally(() => {
          this.isBusySchoolClasses = false;
        });
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: CreateTemporaryLocationRequest = {
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        children: this.form.children,
        target_school_class: this.form.target_school_class,
      };

      this.$emit("onSubmit", request);
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
  },
  created(): void {
    this.fetchSchoolClasses();
  },
});
