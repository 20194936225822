

















































import HelpMessage from "@/components/layout/HelpMessage.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import SchoolClassesTable from "@/components/school-classes/table/SchoolClassesTable.vue";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import CreateSchoolClassForm from "@/components/school-classes/form/CreateSchoolClassForm.vue";

export default Vue.extend({
  name: "SchoolClasses",
  components: { HelpMessage, CreateSchoolClassForm, SchoolClassesTable, DefaultLayout },
  data: () => ({
    // dialog
    dialog: false,
  }),
  computed: {
    schoolId(): number | null {
      const school = this.$store.getters["Setting/getSelectedSchool"] as SchoolResponse | null;
      return school !== null ? school.id : null;
    },
    schoolYearId(): number | null {
      const schoolYear = this.$store.getters["Setting/getSelectedSchoolYear"] as SchoolYearResponse | null;
      return schoolYear !== null ? schoolYear.id : null;
    },
  },
  methods: {
    onSubmit(): void {
      this.dialog = false;
      (this.$refs["table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_classes.school_class_created"));
    },
  },
});
