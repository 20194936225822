


















































































import Vue, { PropType } from "vue";
import { AllItems } from "@/api/AbstractClient";
import { UserResponse } from "@/api/user/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import { TranslateResult } from "vue-i18n";
import FamilyClient from "@/api/family/FamilyClient";
import { FamilyResponse } from "@/api/family/types/Responses";
import FamilyListItem from "@/components/users/form/misc/FamilyListItem.vue";

export default Vue.extend({
  name: "AssignChildForm",
  components: { FamilyListItem },
  mixins: [validationMixin],
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyFamilies: 0,
    // form data
    form: {
      family: null as number | null,
      child: null as number | null,
    },
    // enum
    families: [] as FamilyResponse[],
    children: [] as ChildResponse[],
    // filter families by
    filterFamiliesBy: "",
  }),
  validations: {
    form: {
      family: { required },
      child: { required },
    },
  },
  watch: {
    "form.family": function(familyId?: number): void {
      const family = this.families.find((family: FamilyResponse) => family.id === familyId);
      this.children = family ? family.children : [];
    },
    filterFamiliesBy: _.debounce(function(this: any, value?: string): void {
      if (value && value.length > 0) {
        this.fetchFamilies();
      } else {
        this.families = [];
      }
    }, 250),
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    familyErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.family?.$dirty) return errors;
      !this.$v.form.family.required && errors.push(this.$t("children.validation.family.required"));
      return errors;
    },
    childErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.child?.$dirty) return errors;
      !this.$v.form.child.required && errors.push(this.$t("children.validation.child.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UpdateSchoolClassRequest = {
        name: this.schoolClass.name,
        children: this.schoolClass.children.map((child: ChildResponse) => child.id),
        teachers: this.schoolClass.teachers.map((teacher: UserResponse) => teacher.id),
      };

      if (!request.children.includes(_.toInteger(this.form.child))) {
        request.children.push(_.toInteger(this.form.child));
      }

      this.isBusySubmit = true;
      SchoolClassClient.updateSchoolClass(this.schoolId, this.schoolYearId, this.schoolClass.id, request)
        .then((response) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchFamilies(): void {
      let filter = "";
      if (!_.isEmpty(this.filterFamiliesBy)) {
        filter = "fulltext:\"" + this.filterFamiliesBy + "\"";
      }
      this.isBusyFamilies += 1;
      FamilyClient.getFamilies(new AllItems(filter))
        .then((response) => {
          this.families = response.items;
        })
        .finally(() => {
          this.isBusyFamilies -= 1;
        });
    },
  },
  created(): void {
    this.fetchFamilies();
  },
});
