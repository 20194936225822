





















































































































































import Vue, { PropType } from "vue";
import ChildrenTable from "@/components/child/table/ChildrenTable.vue";
import AssignChildForm from "@/components/child/form/AssignChildForm.vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import CreateChildForm from "@/components/child/form/CreateChildForm.vue";
import TemporaryLocationBySourceClassTable
  from "@/components/temporary-locations/table/TemporaryLocationBySourceClassTable.vue";
import TemporaryLocationByTargetClassTable
  from "@/components/temporary-locations/table/TemporaryLocationByTargetClassTable.vue";
import CreateTemporaryLocationForm from "@/components/temporary-locations/form/CreateTemporaryLocationForm.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import ChildrenAttendanceTable from "@/components/child/table/ChildrenAttendanceTable.vue";

export default Vue.extend({
  name: "SchoolClassChildrenTabs",
  components: {
    ChildrenAttendanceTable,
    CreateTemporaryLocationForm,
    TemporaryLocationByTargetClassTable,
    TemporaryLocationBySourceClassTable,
    CreateChildForm,
    AssignChildForm,
    ChildrenTable,
  },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClassId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  data: () => ({
    // tab
    tab: null,
    // dialog
    assignChildDialog: false,
    createChildDialog: false,
    createTemporaryLocationDialog: false,
  }),
  computed: {
    isBusy(): boolean {
      return false; // TODO: implement me
    },
    schoolClassChildren(): ChildResponse[] {
      return this.schoolClass !== null ? this.schoolClass.children : [];
    },
  },
  methods: {
    onSubmitAssignChild(): void {
      this.assignChildDialog = false;
      (this.$refs["children-table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_classes.child_assigned"));
    },
    onSubmitCreateChild(): void {
      this.createChildDialog = false;
      (this.$refs["children-table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_classes.child_created"));
    },
    onSubmitCreateTemporaryLocation(): void {
      this.createTemporaryLocationDialog = false;
      (this.$refs["temporary-location-by-target-class-table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_classes.child_created"));
    },
  },
});
