var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-check")]),_vm._v(" "+_vm._s(_vm.$t("school_classes.attendance"))+" ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-arrow-down")]),_vm._v(" "+_vm._s(_vm.$t("school_classes.still_located_children"))+" ")],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-clock")]),_vm._v(" "+_vm._s(_vm.$t("school_classes.temporarily_located_children"))+" ")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"py-3"},[_c('div',{staticClass:"d-flex"}),(_vm.schoolClass !== null)?_c('children-attendance-table',{ref:"children-attendance-table",attrs:{"school-class":_vm.schoolClass}}):_vm._e()],1),_c('v-tab-item',{staticClass:"py-3"},[(_vm.$isAdmin())?_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":"","disabled":_vm.schoolClass === null}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("school_classes.assign_child"))+" ")],1)]}}],null,false,1862432114),model:{value:(_vm.assignChildDialog),callback:function ($$v) {_vm.assignChildDialog=$$v},expression:"assignChildDialog"}},[(_vm.assignChildDialog && _vm.schoolId !== null && _vm.schoolYearId !== null)?_c('assign-child-form',{attrs:{"school-id":_vm.schoolId,"school-year-id":_vm.schoolYearId,"school-class":_vm.schoolClass},on:{"onClose":function($event){_vm.assignChildDialog = false},"onSubmit":_vm.onSubmitAssignChild}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":"","disabled":_vm.schoolClass === null}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("school_classes.create_child"))+" ")],1)]}}],null,false,39383835),model:{value:(_vm.createChildDialog),callback:function ($$v) {_vm.createChildDialog=$$v},expression:"createChildDialog"}},[(_vm.createChildDialog)?_c('create-child-form',{attrs:{"school-class-id":_vm.schoolClassId},on:{"onClose":function($event){_vm.createChildDialog = false},"onSubmit":_vm.onSubmitCreateChild}}):_vm._e()],1)],1):_vm._e(),_c('children-table',{ref:"children-table",attrs:{"school-id":_vm.schoolId,"school-year-id":_vm.schoolYearId,"school-class-id":_vm.schoolClassId,"school-class":_vm.schoolClass}})],1),_c('v-tab-item',{staticClass:"py-3"},[_c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.$t("temporary_locations.children_from_another_classes_in_this_class"))+" ")]),_c('temporary-location-by-target-class-table',{ref:"temporary-location-by-target-class-table",attrs:{"target-school-class-id":_vm.schoolClassId,"target-school-class":_vm.schoolClass}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.$t("temporary_locations.children_from_this_class_in_another_classes"))+" ")]),_c('v-card-text',[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("temporary_locations.create_temporary_location"))+" ")],1)]}}]),model:{value:(_vm.createTemporaryLocationDialog),callback:function ($$v) {_vm.createTemporaryLocationDialog=$$v},expression:"createTemporaryLocationDialog"}},[(_vm.createTemporaryLocationDialog)?_c('create-temporary-location-form',{attrs:{"school-id":_vm.schoolId,"school-year-id":_vm.schoolYearId,"school-class-id":_vm.schoolClassId,"children":_vm.schoolClassChildren},on:{"onClose":function($event){_vm.createTemporaryLocationDialog = false},"onSubmit":_vm.onSubmitCreateTemporaryLocation}}):_vm._e()],1)],1),_c('temporary-location-by-source-class-table',{ref:"temporary-location-by-source-class-table",attrs:{"source-school-class-id":_vm.schoolClassId,"source-school-class":_vm.schoolClass}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }