var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"client-function-parameters":_vm.clientFunctionParameters,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.child",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.child.full_name)+" ")]}},{key:"item.target_school_class",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.target_school_class.name)+" ")]}},{key:"item.date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_from.format("LL"))+" ")]}},{key:"item.date_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_to.format("LL"))+" ")]}},{key:"item.initiated_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.initiated_by.full_name)+" ")]}},{key:"item.accepted_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.accepted_by ? item.accepted_by.full_name : "-")+" ")]}},{key:"item.accepted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.accepted_at ? item.accepted_at.format("LLL") : "-")+" ")]}},{key:"item.temporary_location_state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.temporary_location_state.name)+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.childDialog),callback:function ($$v) {_vm.childDialog=$$v},expression:"childDialog"}},[(_vm.childDialog && _vm.child !== null && _vm.sourceSchoolClass !== null)?_c('child-detail',{attrs:{"school-class":_vm.sourceSchoolClass,"child":_vm.child},on:{"onClose":function($event){_vm.childDialog = false; _vm.child = null}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }