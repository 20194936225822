


















import Vue from "vue";
import AbstractClient from "@/api/AbstractClient";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import _ from "lodash";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";

export default Vue.extend({
  name: "SchoolClassesTable",
  components: { DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    client(): AbstractClient {
      return new SchoolClassClient();
    },
    clientFunction(): any {
      return this.$isAdmin() ? SchoolClassClient.getSchoolClasses : SchoolClassClient.getMySchoolClasses;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolId, this.schoolYearId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("school_classes.name")),
          value: "name",
          filter: {
            key: "name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].name"],
          },
        },
        {
          text: _.toString(this.$t("school_classes.teachers_count")),
          value: "teachers",
          export: {
            json_paths: ["$.items[*].teachers.length"],
          },
        },
        {
          text: _.toString(this.$t("school_classes.permanent_children_count")),
          value: "children",
          export: {
            json_paths: ["$.items[*].children.length"],
          },
        },
      ];
    },
    actions: function(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (schoolClass: SchoolClassResponse): void => {
            this.$router.push({
              name: "schoolClass",
              params: {
                schoolClassId: _.toString(schoolClass.id),
              },
            });
          },
        },
      ];
    },
  },
  methods: {
    refresh() {
      (this.$refs["table"] as any).refresh();
    },
  },
});
