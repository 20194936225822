import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";

export default class TeacherClient extends AbstractClient {
  /**
   * Perform get teachers request
   * @param pagination pagination/filters/sort configuration
   * @param schoolClassId school Class ID
   * @param options client options
   */
  static getTeachers(schoolClassId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<UserResponse>> {
    return AbstractClient.get("/api/teachers/by-class/" + schoolClassId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(UserResponse, response.data));
  }
}
