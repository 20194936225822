














































import Vue, { PropType } from "vue";
import TemporaryLocationClient from "@/api/temporary-locations/TemporaryLocationClient";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import TemporaryLocationState from "@/enums/TemporaryLocationState";
import { TemporaryLocationResponse } from "@/api/temporary-locations/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import ChildDetail from "@/components/child/misc/ChildDetail.vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";

export default Vue.extend({
  name: "TemporaryLocationByTargetClassTable",
  components: { ChildDetail, DataTable },
  props: {
    targetSchoolClassId: {
      type: Number,
      required: true,
    },
    targetSchoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  data: () => ({
    // dialogs
    childDialog: false,
    // selected child
    child: null as ChildResponse | null,
  }),
  computed: {
    client(): TemporaryLocationClient {
      return new TemporaryLocationClient();
    },
    clientFunction(): any {
      return TemporaryLocationClient.getTemporaryLocationsByTargetClass;
    },
    clientFunctionParameters(): any[] {
      return [this.targetSchoolClassId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t("temporary_locations.child"),
          value: "child",
          filter: {
            key: ["child.first_name", "child.last_name"],
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].child.first_name", "$.items[*].child.last_name"],
            delimiter: " ",
          },
        },
        {
          text: this.$t("temporary_locations.source_school_class"),
          value: "source_school_class",
          filter: {
            key: "source_school_class.name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].source_school_class.name"],
          },
        },
        {
          text: this.$t("temporary_locations.date_from"),
          value: "date_from",
          filter: {
            key: "date_from",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_from"],
          },
        },
        {
          text: this.$t("temporary_locations.date_to"),
          value: "date_to",
          filter: {
            key: "date_to",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].date_to"],
          },
        },
        {
          text: this.$t("temporary_locations.initiated_by"),
          value: "initiated_by",
          filter: {
            key: ["initiated_by.first_name", "initiated_by.last_name"],
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].initiated_by.first_name", "$.items[*].initiated_by.last_name"],
            delimiter: " ",
          },
        },
        {
          text: this.$t("temporary_locations.accepted_by"),
          value: "accepted_by",
          filter: {
            key: ["accepted_by.first_name", "accepted_by.last_name"],
            type: DataTableFilterType.TEXT,
          },
        },
        {
          text: this.$t("temporary_locations.accepted_at"),
          value: "accepted_at",
          filter: {
            key: "accepted_at",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].accepted_at"],
          },
        },
        {
          text: this.$t("temporary_locations.state"),
          value: "temporary_location_state",
          filter: {
            key: "temporary_location_state.id",
            type: DataTableFilterType.SELECT,
            options: [
              {
                text: this.$t("data_table.all"),
                value: null,
              },
              {
                text: this.$t("temporary_locations.accepted"),
                value: TemporaryLocationState.ACCEPTED.valueOf(),
              },
              {
                text: this.$t("temporary_locations.waiting_for_acceptance"),
                value: TemporaryLocationState.WAITING_FOR_ACCEPTANCE.valueOf(),
                default: true,
              },
            ],
          },
          export: {
            json_paths: ["$.items[*].temporary_location_state.name"],
          },
        },
      ];
    },
    actions: function(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (temporaryLocation: TemporaryLocationResponse): void => {
            this.child = temporaryLocation.child;
            this.childDialog = true;
          },
        },
        {
          name: "accept",
          icon: "mdi-account-check",
          color: "success",
          tooltip: this.$t("temporary_locations.accept"),
          disabled: (temporaryLocation: TemporaryLocationResponse): boolean => temporaryLocation.temporary_location_state.id === TemporaryLocationState.ACCEPTED,
          onClick: (temporaryLocation: TemporaryLocationResponse): Promise<TemporaryLocationResponse> => TemporaryLocationClient.acceptTemporaryLocation(temporaryLocation.id),
          refetch: true,
        },
        {
          name: "revoke",
          icon: "mdi-account-cancel",
          color: "warning",
          tooltip: this.$t("temporary_locations.revoke"),
          disabled: (temporaryLocation: TemporaryLocationResponse): boolean => temporaryLocation.temporary_location_state.id === TemporaryLocationState.WAITING_FOR_ACCEPTANCE,
          onClick: (temporaryLocation: TemporaryLocationResponse): Promise<TemporaryLocationResponse> => TemporaryLocationClient.revokeTemporaryLocation(temporaryLocation.id),
          refetch: true,
        },
      ];
    },
  },
  methods: {
    refresh() {
      (this.$refs["table"] as any).refresh();
    },
  },
});
