














































import Vue, { PropType } from "vue";
import EnumerationClient from "@/api/enum/EnumerationClient";
import FamilyClient from "@/api/family/FamilyClient";
import { UpdateFamilyRequest } from "@/api/family/types/Requests";
import { FamilyResponse, ParentRelationshipTypeResponse } from "@/api/family/types/Responses";
import ParentRelationshipType from "@/enums/ParentRelationshipType";
import { TranslateResult } from "vue-i18n";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "EditFamilyForm",
  mixins: [validationMixin],
  props: {
    family: {
      type: Object as PropType<FamilyResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyParentRelationshipTypes: false,
    // enum
    parentRelationshipTypes: [] as ParentRelationshipTypeResponse[],
    // form data
    form: {
      parent_relationship_type: ParentRelationshipType.MARRIED_COUPLE,
    } as UpdateFamilyRequest,
  }),
  validations: {
    form: {
      parent_relationship_type: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyParentRelationshipTypes;
    },
    dialogTitle(): TranslateResult {
      return this.$t("family.edit_family");
    },
    parentRelationshipTypesErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.parent_relationship_type?.$dirty) return errors;
      !this.$v.form.parent_relationship_type.required && errors.push(this.$t("family.validation.parent_relationship_type.required"));
      return errors;
    },
  },
  methods: {
    initDefaults(): void {
      this.form.parent_relationship_type = this.family.parent_relationship_type.id;
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UpdateFamilyRequest = {
        parent_relationship_type: this.form.parent_relationship_type,
      };

      this.isBusySubmit = true;
      FamilyClient.updateFamily(this.family.id, request)
        .then((response: FamilyResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchParentRelationshipTypes(): void {
      this.isBusyParentRelationshipTypes = true;
      EnumerationClient.getParentRelationshipTypes()
        .then((response: ParentRelationshipTypeResponse[]) => {
          this.parentRelationshipTypes = response;
        })
        .finally(() => {
          this.isBusyParentRelationshipTypes = false;
        });
    },
  },
  created(): void {
    this.initDefaults();
    this.fetchParentRelationshipTypes();
  },
});
