

































import EditFamilyForm from "@/components/family/form/EditFamilyForm.vue";
import Vue from "vue";
import AbstractClient from "@/api/AbstractClient";
import FamilyClient from "@/api/family/FamilyClient";
import { FamilyResponse } from "@/api/family/types/Responses";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import ParentRelationshipType from "@/enums/ParentRelationshipType";
import _ from "lodash";

export default Vue.extend({
  name: "FamiliesTable",
  components: { EditFamilyForm, DataTable },
  props: {
    schoolClassId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // dialog
    editFamilyDialog: false,
    // family to edit
    familyToEdit: null as FamilyResponse | null,
  }),
  computed: {
    client(): AbstractClient {
      return new FamilyClient();
    },
    clientFunction(): any {
      return FamilyClient.getFamiliesBySchoolClass;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolClassId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("family.authorized_persons")),
          value: "authorized_persons",
          filter: {
            key: ["authorized_person.first_name", "authorized_person.last_name"],
            type: DataTableFilterType.TEXT,
          },
        },
        {
          text: _.toString(this.$t("family.children")),
          value: "children",
          filter: {
            key: ["child.first_name", "child.last_name"],
            type: DataTableFilterType.TEXT,
          },
        },
        {
          text: _.toString(this.$t("family.parent_relationship_type")),
          value: "parent_relationship_type",
          filter: {
            key: "parent_relationship_type.id",
            type: DataTableFilterType.SELECT,
            options: [
              {
                text: this.$t("family.married_couple"),
                value: ParentRelationshipType.MARRIED_COUPLE,
              },
              {
                text: this.$t("family.divorced"),
                value: ParentRelationshipType.DIVORCED,
              },
              {
                text: this.$t("family.shared_custody"),
                value: ParentRelationshipType.SHARED_CUSTODY,
              },
              {
                text: this.$t("family.exclusive_custody"),
                value: ParentRelationshipType.EXCLUSIVE_CUSTODY,
              },
            ],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "edit",
          icon: "mdi-pencil",
          default: true,
          onClick: (family: FamilyResponse): void => {
            this.familyToEdit = family;
            this.editFamilyDialog = true;
          },
        },
      ];
    },
  },
  methods: {
    onSubmit(): void {
      this.onClose();
      this.refresh();
    },
    onClose(): void {
      this.editFamilyDialog = false;
      this.familyToEdit = null;
    },
    refresh() {
      (this.$refs["table"] as any).refresh();
    },
  },
});
