








import Vue from "vue";
import SchoolClassForm from "@/components/school-classes/form/SchoolClassForm.vue";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { CreateSchoolClassRequest } from "@/api/school-class/types/Requests";

export default Vue.extend({
  name: "CreateSchoolClassForm",
  components: { SchoolClassForm },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateSchoolClassRequest): void {
      this.isBusySubmit = true;
      SchoolClassClient.createSchoolClass(this.schoolId, this.schoolYearId, request)
        .then((response: SchoolClassResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
