









import Vue, { PropType } from "vue";
import SchoolClassForm from "@/components/school-classes/form/SchoolClassForm.vue";
import { UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";

export default Vue.extend({
  name: "EditSchoolClassForm",
  components: { SchoolClassForm },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: UpdateSchoolClassRequest): void {
      this.isBusySubmit = true;
      SchoolClassClient.updateSchoolClass(this.schoolId, this.schoolYearId, this.schoolClass.id, request)
        .then((response: SchoolClassResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
