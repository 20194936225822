









import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import NotificationsTable from "@/components/notifications/table/NotificationsTable.vue";
import PageTitle from "@/components/layout/PageTitle.vue";

export default Vue.extend({
  name: "Notifications",
  components: { PageTitle, NotificationsTable, DefaultLayout },
});
