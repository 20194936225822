














































































































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { SchoolClassRequest } from "@/api/school-class/types/Requests";
import { UserResponse } from "@/api/user/types/Responses";
import { AllItems } from "@/api/AbstractClient";
import SchoolUserClient from "@/api/school/SchoolUserClient";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";

export default Vue.extend({
  name: "SchoolClassForm",
  mixins: [validationMixin],
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    defaults: {
      type: Object as PropType<SchoolClassResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyTeachers: false,
    isBusySchoolYears: false,
    isBusySchoolClasses: 0,
    // enum
    teachers: [] as UserResponse[],
    schoolClasses: [] as SchoolClassResponse[],
    // form data
    form: {
      name: "",
      teachers: [],
      children: [],
    } as SchoolClassRequest,
    // show class and children inputs
    includeChildrenFromExistingClass: false,
    selectedSchoolClassId: null as number | null,
  }),
  validations: {
    form: {
      name: { required },
      teachers: {},
      children: {},
    },
  },
  watch: {
    includeChildrenFromExistingClass(): void {
      this.selectedSchoolClassId = null;
    },
    selectedSchoolClassId(): void {
      this.form.children = [];
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyTeachers || this.isBusySchoolYears || this.isBusySchoolClasses > 0;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("school_classes.create_school_class")
        : this.$t("school_classes.edit_school_class");
    },
    nameErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.name?.$dirty) return errors;
      !this.$v.form.name.required && errors.push(this.$t("school_classes.validation.name.required"));
      return errors;
    },
    teachersErrors(): TranslateResult[] {
      return [];
    },
    childrenErrors(): TranslateResult[] {
      return [];
    },
    groupedSchoolClasses(): any[] {
      if (this.schoolClasses.length === 0) {
        return [];
      }
      const groupedSchoolClasses = {} as any;
      this.schoolClasses.forEach(schoolClass => {
        if (!groupedSchoolClasses[schoolClass.schoolYear.name]) {
          groupedSchoolClasses[schoolClass.schoolYear.name] = [];
        }
        groupedSchoolClasses[schoolClass.schoolYear.name].push(schoolClass);
      });
      const items = [];
      for (const [schoolYearName, schoolClasses] of Object.entries<any>(groupedSchoolClasses)) {
        items.push({ header: schoolYearName });
        schoolClasses.forEach((schoolClass: SchoolClassResponse) => {
          items.push({ id: schoolClass.id, name: schoolClass.name });
        });
      }
      return items;
    },
    selectedSchoolClass(): SchoolClassResponse | null {
      if (this.selectedSchoolClassId === null) {
        return null;
      }
      const schoolClass = this.schoolClasses.find((schoolClass) => schoolClass.id === this.selectedSchoolClassId);
      return schoolClass ?? null;
    },
    selectedSchoolClassChildren(): ChildResponse[] {
      const schoolClass = this.selectedSchoolClass;
      return schoolClass ? schoolClass.children : [];
    },
  },
  methods: {
    fetchTeachers(): void {
      if (this.defaults) {
        return;
      }
      this.isBusyTeachers = true;
      const filter = "isnotnull:teacher.id";
      SchoolUserClient.getUsers(this.schoolId, new AllItems(filter))
        .then((response) => {
          this.teachers = response.items;
        })
        .finally(() => {
          this.isBusyTeachers = false;
        });
    },
    fetchSchoolClasses(): void {
      if (this.defaults) {
        return;
      }
      this.isBusySchoolYears = true;
      SchoolYearClient.getSchoolYears(this.schoolId, new AllItems())
        .then((response) => {
          response.items.forEach((schoolYear: SchoolYearResponse) => {
            this.isBusySchoolClasses += 1;
            SchoolClassClient.getSchoolClasses(this.schoolId, schoolYear.id, new AllItems())
              .then((response) => {
                response.items.forEach((schoolClass: SchoolClassResponse) => {
                  this.schoolClasses.push(schoolClass);
                });
              })
              .finally(() => {
                this.isBusySchoolClasses -= 1;
              });
          });
        })
        .finally(() => {
          this.isBusySchoolYears = false;
        });
    },
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: SchoolClassRequest = {
        name: this.form.name,
        children: this.form.children,
        teachers: this.form.teachers,
      };

      this.$emit("onSubmit", request);
    },
    onClose(): void {
      this.$parent.$emit("onClose");
    },
    initDefaults(): void {
      if (this.defaults) {
        this.form.name = this.defaults.name;
        this.form.teachers = this.defaults.teachers.map((teacher: UserResponse) => teacher.id);
        this.form.children = this.defaults.children.map((child: ChildResponse) => child.id);
      }
    },
  },
  created(): void {
    this.fetchTeachers();
    this.fetchSchoolClasses();
    this.initDefaults();
  },
});
