





import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "PageTitle",
  props: {
    title: {
      type: String as PropType<string | null>,
    },
  },
  computed: {
    show(): boolean {
      return this.title !== null;
    },
  },
});
