







































































































import FamiliesTable from "@/components/family/table/FamiliesTable.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import _ from "lodash";
import EditSchoolClassForm from "@/components/school-classes/form/EditSchoolClassForm.vue";
import TeachersTable from "@/components/users/table/TeachersTable.vue";
import AssignTeacherForm from "@/components/teacher/form/AssignTeacherForm.vue";
import SchoolClassChildrenTabs from "@/components/school-classes/misc/SchoolClassChildrenTabs.vue";

export default Vue.extend({
  name: "SchoolClass",
  components: {
    FamiliesTable,
    SchoolClassChildrenTabs,
    AssignTeacherForm,
    TeachersTable,
    EditSchoolClassForm,
    PageSubtitle,
    PageTitle,
    DefaultLayout,
  },
  data: () => ({
    // loading
    isBusySchoolClass: false,
    isBusyDelete: false,
    // dialog
    editSchoolClassDialog: false,
    assignTeacherDialog: false,
    // data
    schoolClass: null as SchoolClassResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusySchoolClass || this.isBusyDelete;
    },
    schoolId(): number | null {
      const school = this.$store.getters["Setting/getSelectedSchool"] as SchoolResponse | null;
      return school !== null ? school.id : null;
    },
    schoolYearId(): number | null {
      const schoolYear = this.$store.getters["Setting/getSelectedSchoolYear"] as SchoolYearResponse | null;
      return schoolYear !== null ? schoolYear.id : null;
    },
    schoolClassId(): number {
      return _.toInteger(this.$route.params.schoolClassId);
    },
  },
  methods: {
    fetchSchoolClass(): void {
      if (this.schoolId === null || this.schoolYearId === null) {
        return;
      }
      this.isBusySchoolClass = true;
      SchoolClassClient.getSchoolClass(this.schoolId, this.schoolYearId, this.schoolClassId)
        .then((response) => {
          this.schoolClass = response;
        })
        .finally(() => {
          this.isBusySchoolClass = false;
        });
    },
    onSubmitEditSchoolClass(schoolClass: SchoolClassResponse): void {
      this.editSchoolClassDialog = false;
      this.schoolClass = schoolClass;
      this.$snackbarSuccess(this.$t("school_classes.school_class_updated"));
    },
    onDeleteSchoolClass(): void {
      if (!confirm(this.$t("school_classes.confirm_delete").toString()) || this.schoolId === null || this.schoolYearId === null) {
        return;
      }
      this.isBusyDelete = true;
      SchoolClassClient.deleteSchoolClass(this.schoolId, this.schoolYearId, this.schoolClassId)
        .then(() => {
          this.$snackbarSuccess(this.$t("school_classes.school_class_deleted"));
          this.$router.push({ name: "schoolClasses" });
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    onSubmitAssignTeacher(): void {
      this.fetchSchoolClass();
      this.assignTeacherDialog = false;
      (this.$refs["teachers-table"] as any).refresh();
      this.$snackbarSuccess(this.$t("school_classes.teacher_assigned"));
    },
  },
  created(): void {
    this.fetchSchoolClass();
  },
});
