







import Vue from "vue";
import ChildForm from "@/components/child/form/ChildForm.vue";
import ChildClient from "@/api/child/ChildClient";
import { ChildResponse } from "@/api/child/types/Responses";
import { CreateChildRequest } from "@/api/child/types/Requests";

export default Vue.extend({
  name: "CreateChildForm",
  components: { ChildForm },
  props: {
    schoolClassId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateChildRequest): void {
      this.isBusySubmit = true;
      ChildClient.createChild(this.schoolClassId, request)
        .then((response: ChildResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
