


























import Vue from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import AbstractClient from "@/api/AbstractClient";
import NotificationClient from "@/api/notification/NotificationClient";
import { AbstractNotificationResponse } from "@/api/notification/types/Responses";
import { AxiosResponse } from "axios";
import _ from "lodash";

export default Vue.extend({
  name: "NotificationsTable",
  components: { DataTable },
  computed: {
    client(): AbstractClient {
      return new NotificationClient();
    },
    clientFunction(): any {
      return NotificationClient.getNotifications;
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("layout.notifications.state")),
          value: "is_read",
          sortable: false,
          export: {
            name: this.$t("layout.notifications.read"),
            json_paths: ["$.items[*].is_read"],
          },
        },
        {
          text: _.toString(this.$t("layout.notifications.name")),
          value: "name",
          sortable: false,
          export: {
            json_paths: ["$.items[*].name"],
          },
        },
        {
          text: _.toString(this.$t("layout.notifications.description")),
          value: "description",
          sortable: false,
          export: {
            json_paths: ["$.items[*].description"],
          },
        },
        {
          text: _.toString(this.$t("layout.notifications.created_at")),
          value: "created_at",
          export: {
            json_paths: ["$.items[*].created_at"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          color: "primary",
          tooltip: this.$t("layout.notifications.detail"),
          default: true,
          onClick: (notification: AbstractNotificationResponse): Promise<any> => NotificationClient.markNotificationAsRead(notification.id).then(() => window.location.replace(notification.url)),
        },
        {
          name: "mark_as_read",
          icon: "mdi-eye-check-outline",
          color: "primary",
          tooltip: this.$t("layout.notifications.mark_as_read"),
          disabled: (notification: AbstractNotificationResponse): boolean => notification.is_read,
          onClick: (notification: AbstractNotificationResponse): Promise<AxiosResponse> => NotificationClient.markNotificationAsRead(notification.id),
          refetch: true,
        },
        {
          name: "mark_as_unread",
          icon: "mdi-eye-remove-outline",
          color: "primary",
          tooltip: this.$t("layout.notifications.mark_as_unread"),
          disabled: (notification: AbstractNotificationResponse): boolean => !notification.is_read,
          onClick: (notification: AbstractNotificationResponse): Promise<AxiosResponse> => NotificationClient.markNotificationAsUnread(notification.id),
          refetch: true,
        },
        {
          name: "delete",
          icon: "mdi-trash-can-outline",
          color: "error",
          tooltip: this.$t("layout.notifications.delete"),
          onClick: (notification: AbstractNotificationResponse): Promise<AxiosResponse> => NotificationClient.deleteNotification(notification.id),
          refetch: true,
        },
      ];
    },
  },
});
