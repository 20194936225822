

























import Vue, { PropType } from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import AbstractClient from "@/api/AbstractClient";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import _ from "lodash";
import ChildClient from "@/api/child/ChildClient";
import { UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import ChildDetail from "@/components/child/misc/ChildDetail.vue";

export default Vue.extend({
  name: "ChildrenTable",
  components: { ChildDetail, DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClassId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: null as unknown as PropType<SchoolClassResponse | null>,
      required: true,
    },
  },
  data: () => ({
    // dialogs
    childDialog: false,
    // selected child
    child: null as ChildResponse | null,
  }),
  computed: {
    client(): AbstractClient {
      return new ChildClient();
    },
    clientFunction(): any {
      return ChildClient.getChildrenBySchoolClass;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolClassId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("children.first_name")),
          value: "first_name",
          filter: {
            key: "first_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].first_name"],
          },
        },
        {
          text: _.toString(this.$t("children.last_name")),
          value: "last_name",
          filter: {
            key: "last_name",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].last_name"],
          },
        },
        {
          text: _.toString(this.$t("children.birthdate")),
          value: "birthdate",
          filter: {
            key: "birthdate",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].birthdate"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (child: ChildResponse): void => {
            this.child = child;
            this.childDialog = true;
          },
        },
        {
          name: "remove",
          icon: "mdi-account-cancel",
          color: "error",
          tooltip: this.$t("children.remove_child_from_class"),
          onClick: (childToRemove: ChildResponse): Promise<SchoolClassResponse> => {
            if (this.schoolClass === null) throw "School class property should not be null";
            const request: UpdateSchoolClassRequest = {
              name: this.schoolClass.name,
              children: this.schoolClass.children
                .filter((child: ChildResponse) => child.id !== childToRemove.id) // do not include removed child ID
                .map((child: ChildResponse) => child.id),
              teachers: this.schoolClass.teachers
                .map((user: UserResponse) => user.id),
            };
            return SchoolClassClient.updateSchoolClass(this.schoolId, this.schoolYearId, this.schoolClassId, request);
          },
          refetch: true,
        },
      ];
    },
  },
  methods: {
    refresh(): void {
      (this.$refs["table"] as any).refresh();
    },
  },
});
