
















































import Vue, { PropType } from "vue";
import SchoolUserClient from "@/api/school/SchoolUserClient";
import { AllItems } from "@/api/AbstractClient";
import { UserResponse } from "@/api/user/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { UpdateSchoolClassRequest } from "@/api/school-class/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "AssignTeacherForm",
  mixins: [validationMixin],
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusyTeachers: false,
    // form data
    form: {
      teacher: null as number | null,
    },
    // enum
    teachers: [] as UserResponse[],
  }),
  validations: {
    form: {
      teacher: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyTeachers;
    },
    filteredTeachers(): UserResponse[] {
      return this.teachers.filter((user: UserResponse): boolean => !this.schoolClass.teachers.map((user: UserResponse): number => user.id).includes(user.id));
    },
    teacherErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.teacher?.$dirty) return errors;
      !this.$v.form.teacher.required && errors.push(this.$t("teachers.validation.teacher.required"));
      return errors;
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: UpdateSchoolClassRequest = {
        name: this.schoolClass.name,
        children: this.schoolClass.children.map((child: ChildResponse) => child.id),
        teachers: this.schoolClass.teachers.map((teacher: UserResponse) => teacher.id),
      };

      if (!request.teachers.includes(_.toInteger(this.form.teacher))) {
        request.teachers.push(_.toInteger(this.form.teacher));
      }

      this.isBusySubmit = true;
      SchoolClassClient.updateSchoolClass(this.schoolId, this.schoolYearId, this.schoolClass.id, request)
        .then((response) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
    fetchTeachers(): void {
      this.isBusyTeachers = true;
      const filter = "isnotnull:teacher.id";
      SchoolUserClient.getUsers(this.schoolId, new AllItems(filter))
        .then((response) => {
          this.teachers = response.items;
        })
        .finally(() => {
          this.isBusyTeachers = false;
        });
    },
  },
  created(): void {
    this.fetchTeachers();
  },
});
